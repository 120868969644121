import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4×6\\@75%1RM`}</p>
    <p>{`Weighted Strict Pullups 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time:`}</p>
    <p>{`200M Run`}</p>
    <p>{`15-Ring Rows`}</p>
    <p>{`15-Parallel Bar Dips `}<em parentName="p">{`(scale to bench dips)`}</em></p>
    <p><strong parentName="p">{`*`}{`This Saturday is the return of our free Partner Workout! Classes are
at 9:00 & 10:15am. If you aren’t a member but would like to attend
please email: Daniel\\@crossfittheville.org to reserve your spot.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      